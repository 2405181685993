@import './common/_base.scss';
@import './components/_header.scss';
@import './components/_footer.scss';
@import './plugin/swiper.min.scss';
@import './components/_projectList.scss';
@import './components/_form.scss';
.mod_header {
    @include headerFontColor();
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    .logo {
        .img {
            background-image: url('../imgs/if-logo-white.svg');
        }
        .desc_wrap .desc {
            background-image: url('../imgs/if-logo-desc-white.svg');
        }
    }
}

.over_scroll {
    @include headerFontColor( #A8A8A8);
    .item {
        &:hover {
            @include headerFontColor(#121212);
        }
    }
    transition: all .2s ease;
    .logo {
        .img {
            background-image: url('../imgs/if-logo.svg');
        }
        .desc_wrap .desc {
            background-image: url('../imgs/if-logo-desc.svg');
        }
    }
}

.mod_coontainer {
    background-color: white;
}

.mod_banner {
    position: relative;
    .list {
        .item {
            width: 100vw;
            height: 100vh;
            @extend .g_bg_img;
            &:nth-of-type(3n+2) {
                background-image: url('../imgs/index/wowstore-banner.jpg')
            }
            &:nth-of-type(3n) {
                background-image: url('../imgs/index/ifanr-banner.jpg')
            }
            &:nth-of-type(3n+1) {
                background-image: url('../imgs/index/appso-banner.jpg')
            }
            .link {
                display: block;
                @extend .g_max;
            }
            .bottom {
                position: absolute;
                bottom: 34px;
                width: 100%;
                padding-left: 40px;
                font-size: 24px;
                line-height: 1.5;
                color: white;
            }
        }
    }
    .pagination_wrap {
        position: absolute;
        bottom: 75px;
        width: 100%;
        margin-left: -7px;
        padding-left: 40px;
        line-height: 1.5;
        color: white;
        font-weight: lighter;
        font-size: 24px;
        text-align: left;
        z-index: 2;
        .svg_icon {
            position: relative;
            top: 4px;
            @include getSquare(24px);
            cursor: pointer;
            outline: 0;
        }
        .svg_arrow_left {
            margin-right: 16px;
        }
        .svg_arrow_right {
            margin-left: 16px;
        }
        .swiper-pagination {
            position: initial;
            display: inline;
        }
    }
    .svg_arrow_down {
        @extend .g_c_mid;
        bottom: 22px;
        @include getSquare(80px);
        color: white;
        opacity: .7;
        cursor: pointer;
        z-index: 2;
        -webkit-animation: scroll 1s cubic-bezier(.445, .05, .55, .95);
        -moz-animation: scroll 1s cubic-bezier(.445, .05, .55, .95);
        -ms-animation: scroll 1s cubic-bezier(.445, .05, .55, .95);
        animation: scroll 1s cubic-bezier(.445, .05, .55, .95);
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}

.mod_logo {
    .wrap {
        width: 1120px;
        margin: 0 auto;
        padding: 120px 0 80px;
    }
    .list {
        .item {
            position: relative;
            padding: 7.15%;
            margin: 0 12px 40px 12px;
            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
    }
}

.mod_contact_space {
    position: relative;
    height: 667px;
    z-index: -1;
}

.mod_contact {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 80px 0 0;
    background-color: #222222;
    width: 100%;
    height: 665px;
    z-index: 0;
    .contact_wrap {
        width: 1080px;
        margin: 0 auto;
        .title {
            font-size: 24px;
            color: #6F6F6F;
            margin-bottom: 80px;
            @extend .f_re;
        }
        form {
            width: 400px;
            margin: 0 auto;
            .txt {
                color: #6f6f6f;
            }
            .left {
                color: #FFFFFF;
            }
            .name,
            .contact,
            .desc {
                .input_foucs {
                    border-color: rgba(241, 59, 3, 0.35);
                }
            }
        }
        .desc {
            padding: 29px 0 28px;
            border-bottom: 1px solid rgba(111, 111, 111, 0.35);
            .input {
                position: relative;
                overflow-y: auto;
                border: none;
                padding: 0;
            }
        }
        .list {
            background-color: #222;
        }
        .item {
            color: rgba(255, 255, 255, 0.5);
            &:hover {
                color: #FFF;
            }
        }
        .input {
            width: 100%; // padding: 30px 0;
            border-bottom: 1px solid rgba(111, 111, 111, 0.35);
            color: #FFFFFF;
            caret-color: #F13B03;
        }
        .btn {
            margin: 40px 0 0 -36px;
            width: 72px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #6F6F6F;
            border-radius: 2px;
            font-size: 14px;
            color: #7D7D7D;
            background-color: transparent;
            float: right;
            &:hover {
                color: #fff;
            }
        }
        .input_foucs {
            border-bottom: 1px solid rgba(255, 87, 34, 0.35);
            &+.txt {
                color: #FF5722;
                opacity: 0.5;
            }
        }
    }
}

.mod_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    .list .item {
        color: #6F6F6F;
    }
    .list .txt {
        color: #6F6F6F;
        div {
            display: inline;
        }
    }
}

.mod_project,
.mod_logo {
    position: relative;
    background-color: white;
    z-index: 1;
}

@media screen and (max-width:1439px) {
    .mod_logo .wrap {
        width: 77.778%;
        padding: 8.333% 0 5.556%;
        .list .item {
            margin: 0 12px 3.57% 12px;
        }
    }
    .mod_contact .contact_wrap {
        width: 75%;
    }
}

@media(max-width:767px) {
    .mod_header {
        background-color: transparent;
        .nav {
            .icon_menu {
                .menu_item_1 {
                    background-color: white;
                }
                .menu_item_2 {
                    background-color: white;
                }
            }
        }
    }
    .h5_over_scroll {
        background-color: rgba(255, 255, 255, .96);
        .nav .icon_menu {
            .menu_item_1 {
                background-color: #B0B0B0 !important;
            }
            .menu_item_2 {
                background-color: #B0B0B0 !important;
            }
        }
        .logo {
            .img {
                background-image: url(../imgs/if-logo.svg);
            }
            .desc_wrap .desc {
                background-image: url(../imgs/if-logo-desc.svg);
            }
        }
    }
    .mod_banner {
        .svg_arrow_down {
            display: none;
        }
        .list .item {
            height: 138.67vw;
            &:nth-of-type(3n+2) {
                background-image: url('../imgs/index/wowstore-mobile-banner.jpg')
            }
            &:nth-of-type(3n) {
                background-image: url('../imgs/index/ifanr-mobile-banner.jpg')
            }
            &:nth-of-type(3n+1) {
                background-image: url('../imgs/index/appso-mobile-banner.jpg')
            }
        }
        .list .item .bottom {
            bottom: 14px;
            padding: 0 16px;
            font-size: 18px;
        }
        .pagination_wrap {
            bottom: 78px;
            padding-left: 16px;
            font-size: 14px;
            .svg_icon {
                top: 7px;
            }
            .svg_arrow_left {
                margin-right: -4px;
            }
            .svg_arrow_right {
                margin-left: -4px;
            }
        }
    }
    .mod_logo .wrap {
        width: 82.93%;
        .list .item {
            padding: 22.508%;
            margin: auto;
        }
    }
    .mod_contact .contact_wrap {
        width: 87.2%;
        .title {
            margin-bottom: 12.234%;
        }
        .content_wrap {
            width: 100%;
        }
        form {
            width: 100%;
            .name,
            .contact,
            .desc {
                .input {
                    border: none;
                    @include getBorder(bottom,
                    rgba(111,
                    111,
                    111,
                    0.35));
                }
                .input_foucs {
                    border: none;
                    @include getBorder(bottom,
                    rgba(255,
                    87,
                    34,
                    0.35));
                }
            }
        }
    }
    .mod_contact_space {
        display: none;
    }
    .mod_contact {
        position: relative;
        padding: 40px 0 30px;
        height: auto;
        .contact_wrap {
            .desc {
                height: auto !important;
                padding: 0 !important;
                border: none !important;
                .input {
                    position: relative;
                    height: auto !important;
                    max-height: none !important;
                    margin-top: 0 !important;
                    overflow-y: auto !important;
                    padding: 20px 0 !important;
                }
                .input_foucs {
                    padding: 32px 0 8px 64px !important;
                    height: auto !important;
                    max-height: none !important;
                    margin-top: 0 !important;
                    overflow-y: auto !important;
                }
            }
        }
    }
    .mod_footer {
        position: initial;
        margin-top: 100px;
        padding-bottom: 50px;
        .list {
            width: 100%;
            .item {
                color: #6F6F6F;
                .svg_icon {
                    @include getSquare(30px);
                }
            }
            .txt {
                color: #575757;
                top: -100px;
                font-size: 11px;
                .highlight {
                    color: #575757;
                }
                div {
                    display: block;
                }
            }
        }
    }
}

@media(max-width:375px) {
    .mod_contact .contact_wrap {
        width: 100%;
        padding: 0 24px;
    }
}

@-webkit-keyframes scroll {
    0%,
    100% {
        -webkit-transform: translate3d(-50%, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(-50%, 10px, 0);
    }
}

@-moz-keyframes scroll {
    0%,
    100% {
        -moz-transform: translate3d(-50%, 0, 0);
    }
    50% {
        -moz-transform: translate3d(-50%, 10px, 0);
    }
}

@keyframes scroll {
    0%,
    100% {
        transform: translate3d(-50%, 0, 0);
    }
    50% {
        transform: translate3d(-50%, 10px, 0);
    }
}