@import '_collection';
@import 'reset';
/*flex布局*/

.flex {
    // display: box;
    display: -webkit-box; // display: -moz-box;
    // display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

//垂直居中
//这个命名和水平居中搞混了, 那就这样先错下去吧..
.flex_hc {
    @extend .flex; // -webkit-box-align: center;
    -webkit-align-items: center; // -moz-align-items: center;
    // -ms-align-items: center;
    // -o-align-items: center;
    align-items: center;
}

//水平居中
.flex_vc {
    @extend .flex; // -webkit-box-pack: center;
    -webkit-justify-content: center; // -moz-justify-content: center;
    // -ms-justify-content: center;
    // -o-justify-content: center;
    justify-content: center;
}

//水平,垂直居中
.flex_v_h {
    @extend .flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

//两端对齐
.flex_justify {
    @extend .flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

//垂直居中 两段对齐
.flex_v_justify {
    @extend .flex_justify;
    @extend .flex_hc;
}

.flex_h_avg {
    @extend .flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.g_max {
    width: 100%;
    height: 100%;
}

.g_img {
    width: 100%;
    background-color: #ebebeb;
}

.g_v_mid {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.g_c_mid {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}

.g_v_c_mid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
}

.g_bg_img {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-position: center center;
}

/*字体相关*/

/*UI组件*/

[class^="btn_"],
[class*=" btn_"] {
    @extend .flex_v_h;
    -webkit-tap-highlight-color: transparent;
}

.ui_pop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend .flex_v_h;
    background: rgba(0, 0, 0, 0);
    z-index: 1000;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 999;
}

.svg_icon {
    display: inline-block;
}

.svg_none {
    position: absolute;
    width: 0;
    height: 0;
}

.none {
    display: none !important;
}

.hide {
    visibility: hidden !important;
}

// 图标处理
.icon,
.svg_icon {
    display: inline-block;
    color: inherit;
}

.svg_icon {
    @include getSquare(16px);
    svg {
        @extend .g_max;
    }
}

//填充svg颜色,使其属性color生效
.svg_icon path,
.svg_icon use {
    fill: currentColor;
}

.m_grid {
    text-align: justify;
    line-height: 0;
    /*解决inline-box下内联元素基线问题*/
    .m_justify {
        transform: translateY(0);
        display: inline-block;
        vertical-align: top;
        transition: all .2s ease;
        box-sizing: border-box;
    }
    .fill {
        height: 0 !important;
        margin-bottom: 0 !important;
    }
    .m_justify_fix {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 0;
        overflow: hidden;
    }
}

.over_scroll {
    transition: all .2s ease;
    &:hover {
        background-color: rgba(255, 255, 255, .96);
    }
}

.f_pf {
    font-family: 'PingFangSC-Medium', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
}

.f_re {
    font-family: 'PingFangSC-Regular', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
    font-weight: 400;
}

.f_st {
    font-family: 'STSongti-SC-Regular', "Songti SC", '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
}

.f_he {
    font-family: 'helvetica', 'PingFangSC', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
}
.f_sc_l {
    font-family: 'PingFangSC-Light', 'PingFangSC', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
}