.mod_project {
    .wrap {
        padding-top: 80px;
        margin: 0 auto;
        width: 1280px;
    }
    .list {
        .item {
            transform: translateY(50px);
            opacity: 0;
            width: 48.439%;
            margin-bottom: 40px;
            .img_wrap {
                position: relative;
                padding-top: 54.839%;
                &:hover{
                    &+.type{
                        opacity: 1;
                    }
                }
            }
            .img {
                position: absolute;
                top: 0;
                left: 0;
                @extend .g_max;
                transition: all .2s ease;
                &[src=" "] {
                    opacity: 0;
                }
                &:hover {
                    opacity: .8;
                }
            }
            .txt {
                @extend .f_pf;
                font-size: 18px;
                line-height: 1.4;
                margin-top: 16px;
            }
            a:hover {
                &+.type {
                    opacity: 1;
                }
            }
            .type {
                position: absolute;
                bottom: -14px;
                color: $color_main;
                font-size: 12px;
                opacity: 0;
                transition: all .2s ease;
            }
        }
        .wrap_move {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .view_more {
        text-align: center;
        .btn {
            display: inline-block;
            line-height: 30px;
            width: 90px;
            font-size: 14px;
            color: #7D7D7D;
            border: 1px solid #DADADA;
            border-radius: 2px;
            .svg_more_arrow {
                position: relative;
                top: 2px;
                margin-left: -4px;
                @include getSquare(14px);
                transition: all .2s ease;
            }
            &:hover {
                color: $color_main;
                border-color: $color_main;
                .svg_more_arrow {
                    transform: translateX(4px);
                }
            }
        }
    }
}

@media screen and (max-width:1439px) {
    .mod_project .wrap {
        width: 88.889%;
    }
    .mod_project .list .item {
        margin-bottom: 2.7778%;
    }
}

@media(max-width:767px) {
    .mod_project .wrap {
        width: 91.47%;
        padding-top: 6.4%;
        .list .item {
            width: 100%;
            margin-bottom: 9.33%;
            .txt {
                font-size: 16px;
                margin-top: 2.332%;
            }
        }
    }
}