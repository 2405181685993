
// input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
// }
form{
    position: relative;
    .name,.contact,.desc{
        position: relative;
        .txt{
            position: absolute;
            top: 29px;
            left: 0;
            line-height: 22px;
            color: #A8A8A8;
            font-size: 16px;
            transition: transform .3s ease;
            -webkit-transition: transform .3s ease;
            -moz-transition: transform .3s ease;
            -ms-transition: transform .3s ease;
        }
        .left{
            position: absolute;
            top: 46px;
            left: 0;
            padding:0 0 11px;
            width: 76px;
            display: none;
            font-size: 16px;
            color: #3A3A3A;
            line-height: 22px;
            z-index: 1;
        }
    }
    .input{
        position: relative;
        z-index: 1;
        width: 400px;
        line-height: 22px;
        padding: 29px 0 28px;
        border:none;
        border-bottom: 1px solid #EFEFEF;
        outline: none;
        font-size: 16px;
        color: #3A3A3A;
        caret-color:#FF5722;
        background-color: transparent;
        -webkit-user-select:text;
    }
    .input_foucs{
        padding: 46px 0 11px 46px;
        border-bottom: 1px solid rgba(241,59,3,0.35);
        &+.txt {
            transform: translate3d(0,-13px,0);
            -webkit-transform: translate3d(0,-13px,0);
            -moz-transform: translate3d(0,-13px,0);
            -ms-transform: translate3d(0,-13px,0);
            font-size: 14px;
            color: rgb(241,59,3);
            line-height: 20px;
            z-index: 2;
        }
    
        &~.left {
            display: block;
        }
    }
    button{
        position: absolute;
        right: 0;
        width: 72px;
        height: 30px;
        line-height: 26px;
        background: #FFFFFF;
        border: 1px solid #D3D8DB;
        border-radius: 2px;
        font-size: 14px;
        color: #A8A8A8;
        margin-top: 40px;
        font-family: PingFangSC-Medium;
        &:hover{
            color: #121212;;
        }
    }
}
.mod_toast{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -22px 0 0 -65px;
    width: 130px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    z-index: 10;
    display: none;
}

@media screen and (max-width: 767px) {
    form{
        width: 100%;
        button{
            margin-top: 20px;
            &:hover{
                color: #A8A8A8;
            }
        }
        .input{
            width: 100%;
            padding: 20px 0;  //6.12% 0
            line-height: 20px;
            font-size: 14px;
            border-bottom: none;
            @include getBorder(bottom,#EFEFEF);
        }
        .name,.contact,.desc{
            // min-height: 60px;
            .txt{
                top: 20px;
                font-size: 14px;
            }
            .left{
                top: 32px;
                left: 0;
                padding: 0px 0 7px; //  2.142%
                font-size: 14px;
                line-height: 20px;
            }
        }
        .input_foucs{
            padding: 32px 0 8px  64px;  // 9.79% 0 2.448% 25.995% 
            @include getBorder(bottom,rgba(241,59,3,0.35));
    
            &+.txt {
                transform: translate3d(0,-12px,0);
                -webkit-transform: translate3d(0,-12px,0);
                -moz-transform: translate3d(0,-12px,0);
                -ms-transform: translate3d(0,-12px,0);
                font-size: 12px;
                line-height: 20px;
            }
    
            &~.left {
                display: block;
            }
        }
    }
}
