@charset "utf-8";
html {
    /*-webkit-text-size-adjust:100%;
    -ms-text-size-adjust:100%;*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
}

body {
    font-size: 12px;
    -webkit-text-size-adjust: 100% !important;
    font-family: 'helvetica','PingFangSC','微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
}
@media(max-width:767px){
    -webkit-user-select: none;
    /*webkit浏览器*/
    user-select: none;
}

body,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
optgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
optgroup,
menu,
nav,
section {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    a:active {
        background-color: transparent;
    }
}

small,
cite,
code,
em,
th,
i,
b {
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input,
select,
textarea,
button {
    font-size: 1em;
    outline: none;
    /* -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;*/
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

button,
input[type=button],
input[type=submit] {
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0 none;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img,
iframe {
    border: 0 none;
}

img {
    display: inline-block;
    vertical-align: bottom;
    -ms-interpolation-mode: bicubic;
    border: none;
}

iframe {
    display: block;
}

ul,
ol {
    list-style: none;
}

li {
    vertical-align: top;
}

q:before,
q:after {
    content: '';
}

.cf:before,
.cf:after {
    content: '';
    display: table;
}

.cf:after {
    clear: both
}

a {
    color: inherit;
    text-decoration: none;
    outline: 0;
}