.mod_footer {
    width: 100%;
    padding: 80px 0 60px;
    .list {
        position: relative;
        @extend .f_re;
        width: 1120px;
        margin: 0 auto;
        font-size: 0;
        .item {
            position: relative;
            display: inline-block;
            margin-right: 20px;
            color: #B4BBBE;
            &:hover {
                color: $color_main;
            }
            &:nth-of-type(3):hover {
                .qr_code {
                    display: block;
                }
            }
            .svg_icon {
                @include getSquare(22px);
            }
            .qr_code {
                position: absolute;
                top: -200px;
                left: 50%;
                margin-left: -83px;
                padding: 8px;
                text-align: center;
                font-size: 14px; // color: #121212;
                background-color: white;
                display: none;
                box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
                z-index: 2;
                &:after {
                    content: " ";
                    position: absolute;
                    bottom: -11px;
                    left: 50%;
                    margin-left: -6px;
                    display: inline-block;
                    border: 6px solid transparent;
                    border-top-color: white;
                }
                .img {
                    @include getSquare(150px);
                }
            }
        }
        .txt {
            display: inline-block;
            font-size: 13px;
            line-height: 22px;
            color: #B4BBBE;
            .highlight {
                color: $color_main;
            }
            .copyright {
                position: absolute;
                top: 0;
                right: 0;
                @extend .f_re;
            }
            b{
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .mod_footer .list {
        width: 77.778%;
    }
}

@media(max-width:767px) {
    .mod_footer {
        padding: 21.334% 0 13.334%;
        .list {
            text-align: center;
            .item {
                .svg_icon {
                    @include getSquare(24px);
                }
                &:nth-child(4) {
                    margin-right: 0;
                }
            }
            .txt {
                position: absolute;
                left: 0;
                top: -39px;
                width: 100%;
                font-size: 12px;
                .copyright {
                    position: initial;
                }
            }
        }
    }
}

@media(max-width:321px) {
    .mod_footer {
        .list .txt {
            font-size: 11px;
        }
    }
}