.mod_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding-right: 80px;
    font-size: 15px;
    font-family: 'PingFangSC-Medium', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
    z-index: 3;
    .logo {
        @extend .g_v_mid;
        font-size: 0;
        left: 40px;
        z-index: 3;
        .img {
            display: inline-block;
            @include getSquare(25px);
            @include getBgImg('../imgs/if-logo.svg');
        }
        .desc_wrap {
            overflow: hidden;
            display: inline-block;
            margin-left: 10px;
            .desc {
                display: inline-block;
                width: 77px;
                height: 24px;
                @include getBgImg('../imgs/if-logo-desc.svg');
                -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -webkit-animation-duration: .45s;
                -moz-animation-duration: .45s;
                -ms-animation-duration: .45s;
                animation-duration: .45s;
                -webkit-animation-direction: alternate;
                -moz-animation-direction: alternate;
                -ms-animation-direction: alternate;
                animation-direction: alternate;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                -ms-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }
        }
    }
    .white .img {
        background-image: url('../imgs/if-logo-white.svg');
    }
    .nav {
        float: right;
        overflow: hidden;
    }
    .list {
        animation-duration: .45s;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        .item {
            float: left;
            line-height: 80px;
            margin: 0 20px;
            a:hover {
                opacity: .8;
            }
        }
    }
    .icon_menu {
        @extend .g_v_mid;
        right: 40px;
        @include getSquare(36px);
        cursor: pointer;
        .icon_menu_items {
            @extend .g_v_c_mid;
            @include getSquare(18px);
            .menu_item_1,
            .menu_item_2 {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: 2px;
                height: 24px;
                margin-left: -1px;
                margin-top: -12px;
                -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                animation-timing-function: cubic-bezier(.215, .61, .355, 1);
                -webkit-animation-duration: .45s;
                -moz-animation-duration: .45s;
                -ms-animation-duration: .45s;
                animation-duration: .45s;
                -webkit-animation-direction: alternate;
                -moz-animation-direction: alternate;
                -ms-animation-direction: alternate;
                animation-direction: alternate;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                -ms-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }
        }
    }
}

.default_open {
    .icon_menu {
        .menu_item_1 {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .menu_item_2 {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}

.header_close {
    .logo .img:hover {
        &+.desc_wrap {
            .desc {
                -webkit-animation-name: descToRight;
                -moz-animation-name: descToRight;
                -ms-animation-name: descToRight;
                animation-name: descToRight;
            }
        }
    }
    .nav .list {
        -webkit-animation-name: navToRight;
        -moz-animation-name: navToRight;
        -ms-animation-name: navToRight;
        animation-name: navToRight;
    }
    .logo .desc {
        -webkit-animation-name: descToLeft;
        -moz-animation-name: descToLeft;
        -ms-animation-name: descToLeft;
        animation-name: descToLeft;
    }
    .icon_menu {
        .menu_item_1 {
            -webkit-animation-name: menu1In;
            -moz-animation-name: menu1In;
            -ms-animation-name: menu1In;
            animation-name: menu1In;
        }
        .menu_item_2 {
            -webkit-animation-name: menu2In;
            -moz-animation-name: menu2In;
            -ms-animation-name: menu2In;
            animation-name: menu2In;
        }
    }
}

.header_open {
    .nav .list {
        -webkit-animation-name: navToLeft;
        -moz-animation-name: navToLeft;
        -ms-animation-name: navToLeft;
        animation-name: navToLeft;
    }
    .logo .desc {
        -webkit-animation-name: descToRight;
        -moz-animation-name: descToRight;
        -ms-animation-name: descToRight;
        animation-name: descToRight;
    }
    .icon_menu {
        .menu_item_1 {
            -webkit-animation-name: menu1Out;
            -moz-animation-name: menu1Out;
            -ms-animation-name: menu1Out;
            animation-name: menu1Out;
        }
        .menu_item_2 {
            -webkit-animation-name: menu2Out;
            -moz-animation-name: menu2Out;
            -ms-animation-name: menu2Out;
            animation-name: menu2Out;
        }
    }
}

@media(max-width:767px) {
    .mod_header {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: 60px;
        padding: 0;
        background-color: white;
        font-family: 'PingFangSC-Light', 'PingFangSC', '微软雅黑', 'Microsoft YaHei', '华文细黑', 'STHeiti', sans-serif;
        .logo {
            left: 16px;
        }
        .nav {
            float: initial;
            background-color: rgba(255, 255, 255, .96);
            .list {
                padding: 22.4% 0 9.067%; //84px 34px 
                .item {
                    text-align: center;
                    float: initial;
                    color: #A8A8A8;
                    margin: 12.267% 0; //46px
                    line-height: 28px;
                    font-size: 20px;
                }
            }
            .icon_menu {
                right: 10px;
                .menu_item_1 {
                    background-color: #B0B0B0;
                }
                .menu_item_2 {
                    background-color: #B0B0B0;
                }
            }
        }
    }
    .default_open {
        .logo .desc_wrap .desc {
            opacity: 0;
        }
        .nav {
            .list {
                display: none;
                -webkit-animation: none;
                -moz-animation: none;
                -ms-animation: none;
                animation: none;
            }
            .icon_menu {
                .menu_item_1 {
                    -webkit-transform: translateY(4px) rotate(90deg);
                    -moz-transform: translateY(4px) rotate(90deg);
                    -ms-transform: translateY(4px) rotate(90deg);
                    transform: translateY(4px) rotate(90deg);
                }
                .menu_item_2 {
                    -webkit-transform: translateY(-4px) rotate(-90deg);
                    -moz-transform: translateY(-4px) rotate(-90deg);
                    -ms-transform: translateY(-4px) rotate(-90deg);
                    transform: translateY(-4px) rotate(-90deg);
                }
            }
        }
    }
    .header_open {
        .logo .desc {
            -webkit-animation-name: descToLeft;
            -moz-animation-name: descToLeft;
            -ms-animation-name: descToLeft;
            animation-name: descToLeft;
        }
        .nav {
            .list {
                display: none;
                -webkit-animation: none;
                -ms-animation: none;
                -moz-animation: none;
                animation: none;
            }
            .icon_menu {
                .menu_item_1 {
                    -webkit-animation-name: h5-menu1Out;
                    -moz-animation-name: h5-menu1Out;
                    -ms-animation-name: h5-menu1Out;
                    animation-name: h5-menu1Out;
                }
                .menu_item_2 {
                    -webkit-animation-name: h5-menu2Out;
                    -moz-animation-name: h5-menu2Out;
                    -ms-animation-name: h5-menu2Out;
                    animation-name: h5-menu2Out;
                }
            }
        }
    }
    .header_close {
        &:after {
            content: " ";
            display: block;
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, .6);
        }
        .logo {
            .img {
                background-image: url(../imgs/if-logo.svg) !important;
            }
            .desc_wrap .desc {
                background-image: url(../imgs/if-logo-desc.svg) !important;
                -webkit-animation-name: descToRight;
                -moz-animation-name: descToRight;
                -ms-animation-name: descToRight;
                animation-name: descToRight;
            }
        }
        .nav {
            .list {
                -webkit-animation-name: h5-nav;
                -moz-animation-name: h5-nav;
                -ms-animation-name: h5-nav;
                animation-name: h5-nav;
            }
            .icon_menu {
                .menu_item_1 {
                    background-color: #B0B0B0 !important;
                    -webkit-animation-name: h5-menu1In;
                    -moz-animation-name: h5-menu1In;
                    -ms-animation-name: h5-menu1In;
                    animation-name: h5-menu1In;
                }
                .menu_item_2 {
                    background-color: #B0B0B0 !important;
                    -webkit-animation-name: h5-menu2In;
                    -moz-animation-name: h5-menu2In;
                    -ms-animation-name: h5-menu2In;
                    animation-name: h5-menu2In;
                }
            }
        }
    }
}

@-webkit-keyframes menu1In {
    0% {
        -webkit-transform: translateY(0) rotate(45deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(90deg);
    }
    100% {
        -webkit-transform: translateY(4px) rotate(90deg);
    }
}

@-moz-keyframes menu1In {
    0% {
        -moz-transform: translateY(0) rotate(45deg)
    }
    50% {
        -moz-transform: translateY(0) rotate(90deg);
    }
    100% {
        -moz-transform: translateY(4px) rotate(90deg);
    }
}

@keyframes menu1In {
    0% {
        transform: translateY(0) rotate(45deg)
    }
    50% {
        transform: translateY(0) rotate(90deg)
    }
    100% {
        transform: translateY(4px) rotate(90deg)
    }
}

@-webkit-keyframes menu2In {
    0% {
        -webkit-transform: translateY(0) rotate(-45deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: translateY(-4px) rotate(-90deg);
    }
}

@-moz-keyframes menu2In {
    0% {
        -moz-transform: translateY(0) rotate(-45deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -moz-transform: translateY(-4px) rotate(-90deg);
    }
}

@keyframes menu2In {
    0% {
        transform: translateY(0) rotate(-45deg)
    }
    50% {
        transform: translateY(0) rotate(-90deg)
    }
    100% {
        transform: translateY(-4px) rotate(-90deg)
    }
}

@-webkit-keyframes menu1Out {
    0% {
        -webkit-transform: translateY(4px) rotate(90deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(90deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(45deg);
    }
}

@-moz-keyframes menu1Out {
    0% {
        -moz-transform: translateY(4px) rotate(90deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(90deg);
    }
    100% {
        -moz-transform: translateY(0) rotate(45deg);
    }
}

@keyframes menu1Out {
    0% {
        transform: translateY(4px) rotate(90deg)
    }
    50% {
        transform: translateY(0) rotate(90deg)
    }
    100% {
        transform: translateY(0) rotate(45deg)
    }
}

@-webkit-keyframes menu2Out {
    0% {
        -webkit-transform: translateY(-4px) rotate(-90deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
    }
}

@-moz-keyframes menu2Out {
    0% {
        -moz-transform: translateY(-4px) rotate(-90deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -moz-transform: translateY(0) rotate(-45deg);
    }
}

@keyframes menu2Out {
    0% {
        transform: translateY(-4px) rotate(-90deg)
    }
    50% {
        transform: translateY(0) rotate(-90deg)
    }
    100% {
        transform: translateY(0) rotate(-45deg)
    }
}

@-webkit-keyframes navToRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(40%);
    }
}

@-moz-keyframes navToRight {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(40%);
    }
}

@keyframes navToRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(40%);
    }
}

@-webkit-keyframes navToLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(40%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes navToLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(40%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes navToLeft {
    0% {
        opacity: 0;
        transform: translateX(40%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@-webkit-keyframes descToLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-60%);
    }
}

@-moz-keyframes descToLeft {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(-60%);
    }
}

@keyframes descToLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-60%);
    }
}

@-webkit-keyframes descToRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-60%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes descToRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(-60%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes descToRight {
    0% {
        opacity: 0;
        transform: translateX(-60%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes h5-menu1In {
    0% {
        -webkit-transform: translateY(4px) rotate(90deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(90deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(45deg);
    }
}

@-moz-keyframes h5-menu1In {
    0% {
        -moz-transform: translateY(4px) rotate(90deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(90deg);
    }
    100% {
        -moz-transform: translateY(0) rotate(45deg);
    }
}

@keyframes h5-menu1In {
    0% {
        transform: translateY(4px) rotate(90deg)
    }
    50% {
        transform: translateY(0) rotate(90deg)
    }
    100% {
        transform: translateY(0) rotate(45deg)
    }
}

@-webkit-keyframes h5-menu2In {
    0% {
        -webkit-transform: translateY(-4px) rotate(-90deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(-45deg);
    }
}

@-moz-keyframes h5-menu2In {
    0% {
        -moz-transform: translateY(-4px) rotate(-90deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -moz-transform: translateY(0) rotate(-45deg);
    }
}

@keyframes h5-menu2In {
    0% {
        transform: translateY(-4px) rotate(-90deg)
    }
    50% {
        transform: translateY(0) rotate(-90deg)
    }
    100% {
        transform: translateY(0) rotate(-45deg)
    }
}

@-webkit-keyframes h5-menu1Out {
    0% {
        -webkit-transform: translateY(0) rotate(45deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(90deg);
    }
    100% {
        -webkit-transform: translateY(4px) rotate(90deg);
    }
}

@-moz-keyframes h5-menu1Out {
    0% {
        -moz-transform: translateY(0) rotate(45deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(90deg);
    }
    100% {
        -moz-transform: translateY(4px) rotate(90deg);
    }
}

@keyframes h5-menu1Out {
    0% {
        transform: translateY(0) rotate(45deg)
    }
    50% {
        transform: translateY(0) rotate(90deg)
    }
    100% {
        transform: translateY(4px) rotate(90deg)
    }
}

@-webkit-keyframes h5-menu2Out {
    0% {
        -webkit-transform: translateY(0) rotate(-45deg);
    }
    50% {
        -webkit-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -webkit-transform: translateY(-4px) rotate(-90deg);
    }
}

@-moz-keyframes h5-menu2Out {
    0% {
        -moz-transform: translateY(0) rotate(-45deg);
    }
    50% {
        -moz-transform: translateY(0) rotate(-90deg);
    }
    100% {
        -moz-transform: translateY(-4px) rotate(-90deg);
    }
}

@keyframes h5-menu2Out {
    0% {
        transform: translateY(0) rotate(-45deg)
    }
    50% {
        transform: translateY(0) rotate(-90deg)
    }
    100% {
        transform: translateY(-4px) rotate(-90deg)
    }
}

@-webkit-keyframes h5-nav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes h5-nav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes h5-nav {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}